<template>
  <div>
    <b-container fluid class="content">
      <el-alert v-if="show_success" title="Success Updated !" type="success" effect="dark"></el-alert>
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <div class="row">
                  <div class="col-12 col-sm-6 col-lg-3 ">
                    <base-input :label="$t('Transaction') + '#'">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Transaction') + '#'"
                        v-model="cur_transaction_no"
                      />
                    </base-input>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-3 ">
                    <base-input :label="$t('From Date')">
                      <b-row>
                        <b-col>
                          <flat-pickr
                            :config="pickr_config"
                            class="form-control"
                            :placeholder="$t('From Date')+'...'"
                            v-model='from_date'
                          >
                          </flat-pickr>
                        </b-col>
                        <b-col v-if="!ac_time">
                          <flat-pickr
                            :config="pickr_config3"
                            class="form-control"
                            :placeholder="$t('From Time') + '...'"
                            v-model="from_time"
                          >
                          </flat-pickr>
                        </b-col>
                      </b-row>
                    </base-input>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-3 ">
                    <base-input :label="$t('To Date')">
                      <b-row>
                        <b-col>
                          <flat-pickr
                            :config="pickr_config"
                            class="form-control"
                            :placeholder="$t('To Date')+'...'"
                            v-model='to_date'
                          >
                          </flat-pickr>
                        </b-col>
                        <b-col v-if="!ac_time">
                          <flat-pickr
                            :config="pickr_config4"
                            class="form-control"
                            :placeholder="$t('To Time') + '...'"
                            v-model="to_time"
                          >
                          </flat-pickr>
                        </b-col>
                      </b-row>
                    </base-input>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-3 ">
                    <base-input :label="$t('Store')">
                      <select class="form-control" v-model="cur_store_id" >
                        <option></option>
                        <option
                          v-for="(loc, idx) in storelist"
                          :key="idx"
                          :value="loc.LocationID"
                        >
                          {{ loc.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 col-lg-3 ">
                    <base-input :label="$t('Payment Method')">
                      <select class="form-control" v-model="cur_payment_method" >
                        <option></option>
                        <option
                          v-for="(method, key) in payment_method_list"
                          :key="key"
                          :value="key"
                        >
                          {{ method.Name1 }}
                        </option>
                      </select>
                    </base-input>
                  </div>
                  <div class="buttonRow col-12 col-sm-12 col-lg-3 " style>
                    <b-form-checkbox
                      class="form-checkbox switchElement"
                      v-model="ac_time"
                      :value="true"
                      :unchecked-value="false"
                      switch
                    >
                      AC Time
                    </b-form-checkbox>
                  </div>
                  <div class="buttonRow col-12 col-sm-12 col-lg-3 " style>
                    <b-form-checkbox
                      v-if="pkadmin"
                      class="form-checkbox switchElement"
                      v-model="is_void"
                      :value="true"
                      :unchecked-value="false"
                      switch
                    >
                      Void Order
                    </b-form-checkbox>
                  </div>
                  <div class="buttonRow col-12 col-sm-12 col-lg-3 ">
                    <div class="row">
                      <div class="col-6">
                        <b-button variant="primary" @click="form_search" style="float: right;">{{
                          $t("Search")
                        }}</b-button>
                      </div>
                      <!-- <div class="col-6" v-show="false">
                        <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export") }}</a>
                      </div> -->
                      <div class="col-6">
                        <xlsx-workbook>
                          <xlsx-sheet
                            v-for="sheet in sheets"
                            :collection="sheet.data"
                            :key="sheet.name"
                            :sheet-name="sheet.name"
                          />
                          <xlsx-download :filename="excel_filename" disable-wrapper-click>
                            <template #default="{download}">
                              <b-button variant="primary" style="float: none;" @click="excel_download(download)" v-show="!loadingDownload">{{ $t("Export") }}</b-button>
                            </template>
                          </xlsx-download>
                        </xlsx-workbook>
                        <!-- <b-button variant="primary" style="float: none;" @click="download()">{{ $t("Export" + '(JS2)') }}</b-button> -->
                        <b-spinner variant="primary" label="Spinning" v-show="loadingDownload"></b-spinner>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-card>
        <b-card-body :style="{overflowX: 'auto' }">
          <div v-bind:style="{ width: report_width }">
            <div v-if="(list_data && list_data.length > 0) || filter_DateTime || filter_Store || 
              filter_TransactionNo || filter_Subtotal || filter_Discount || filter_PaidTotal ||
              filter_Tax || filter_Surcharge || filter_Gratuity || filter_AllTotal || filter_Payment">
              <div v-bind:style="titleStyleObject">{{ $t('POS Transaction Report') }}</div>
              <div class="d-none d-xl-flex"> <!-- ONLY SHOW ON x-LARGE SCREEN AND ABOVE -->
                <div class="container-fluid" style="width: 100%">
                  <div class="row">
                    <div class="col-2" v-bind:style="infoStyleObject">{{ $t('Transaction') + '#:' }}
                    </div>
                    <div class="col-2" v-bind:style="infoStyleObject">{{ $t('Store') + ':' }}
                    </div>
                    <div class="col-2" v-bind:style="infoStyleObject">{{ $t('Payment') + ':' }}
                    </div>
                    <div class="col-2" v-bind:style="infoStyleObject">{{ $t('From Date') + ':' }}
                    </div>
                    <div class="col-2" v-bind:style="infoStyleObject">{{ $t('To Date') + ':' }}
                    </div>
                    <div class="col-2" v-bind:style="infoStyleObject">{{ $t('Print Date') + ':' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2 searchParam" v-bind:style="infodStyleObject">{{ display_transaction_no }}
                    </div>
                    <div class="col-2 searchParam" v-bind:style="infodStyleObject">{{ display_store_name }}
                    </div>
                    <div class="col-2 searchParam" v-bind:style="infodStyleObject">{{ display_payment_name }}
                    </div>
                    <div class="col-2 searchParam" v-bind:style="infodStyleObject">{{ display_from_date }}
                    </div>
                    <div class="col-2 searchParam" v-bind:style="infodStyleObject">{{ display_to_date }}
                    </div>
                    <div class="col-2 searchParam" v-bind:style="infodStyleObject">{{ get_today() }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-none d-md-flex d-xl-none"> <!-- ONLY SHOW ON MEDIUM AND LARGE SCREEN -->
                <div class="container">
                  <div class="row">
                    <div class="col-4" v-bind:style="infoStyleObject">{{ $t('Transaction') + '#:' }}
                    </div>
                    <div class="col-4" v-bind:style="infoStyleObject">{{ $t('Store') + ':' }}
                    </div>
                    <div class="col-4" v-bind:style="infoStyleObject">{{ $t('Payment') + ':' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4" v-bind:style="infodStyleObject">{{ display_transaction_no ? display_transaction_no : '&emsp;' }}
                    </div>
                    <div class="col-4" v-bind:style="infodStyleObject">{{ display_store_name ? display_store_name : '&emsp;' }}
                    </div>
                    <div class="col-4" v-bind:style="infodStyleObject">{{ display_payment_name ? display_payment_name : '&emsp;' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4" v-bind:style="infoStyleObject">{{ $t('From Date') + ':' }}
                    </div>
                    <div class="col-4" v-bind:style="infoStyleObject">{{ $t('To Date') + ':' }}
                    </div>
                    <div class="col-4" v-bind:style="infoStyleObject">{{ $t('Print Date') + ':' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4" v-bind:style="infodStyleObject">{{ display_from_date }}
                    </div>
                    <div class="col-4" v-bind:style="infodStyleObject">{{ display_to_date }}
                    </div>
                    <div class="col-4" v-bind:style="infodStyleObject">{{ get_today() }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex d-md-none"> <!-- ONLY SHOW ON BLOEW MEDIUM SCREEN -->
                <div class="container">
                  <div class="row">
                    <div class="col-6" v-bind:style="infoStyleObject">{{ $t('Transaction') + '#:' }}
                    </div>
                    <div class="col-6" v-bind:style="infoStyleObject">{{ $t('Store') + ':' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" v-bind:style="infodStyleObject">{{ display_transaction_no ? display_transaction_no : '&emsp;' }}
                    </div>
                    <div class="col-6" v-bind:style="infodStyleObject">{{ display_store_name ? display_store_name : '&emsp;' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" v-bind:style="infoStyleObject">{{ $t('From Date') + ':' }}
                    </div>
                    <div class="col-6" v-bind:style="infoStyleObject">{{ $t('To Date') + ':' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" v-bind:style="infodStyleObject">{{ display_from_date ? display_from_date : '&emsp;' }}
                    </div>
                    <div class="col-6" v-bind:style="infodStyleObject">{{ display_to_date ? display_to_date : '&emsp;' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" v-bind:style="infoStyleObject">{{ $t('Payment') + ':' }}
                    </div>
                    <div class="col-6" v-bind:style="infoStyleObject">{{ $t('Print Date') + ':' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" v-bind:style="infodStyleObject">{{ display_payment_name }}
                    </div>
                    <div class="col-6" v-bind:style="infodStyleObject">{{ get_today() }}
                    </div>
                  </div>
                </div>
              </div>
              <b-table
                id="summary-table"
                ref="summary-table"
                striped
                hover
                small
                show-empty
                head-variant="light"
                no-local-sorting
                @sort-changed="handleSortingChanged"
                @row-clicked="handleRowClicked"
                :items="list_data"
                :fields="computedFields"
                :per-page="0"
                :current-page="currentPage"
              >
                <template slot="top-row">
                  <td style="text-align: center" v-if="donote">
                    <span class="summary_row">{{ $t('Note') }}</span>
                  </td>
                  <td>
                    <div class="input-group">
                      <flat-pickr
                        :config="pickr_config2"
                        class="form-control"
                        id="filterTableFP"
                        :placeholder="$t('Click to Filter')"
                        v-model='filter_DateTime'
                        ref="fp"
                      >
                      </flat-pickr>
                      <div v-if="filter_DateTime" class="input-group-append">
                        <button class="clearDateButton" role="button" @click="clearDate">X</button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_Store"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_TransactionNo"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_Subtotal"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_Discount"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_Tax"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_Surcharge"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_Gratuity"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_AllTotal"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      id="filter-input"
                      v-model="filter_PaidTotal"
                      type="search"
                      :placeholder="$t('Type to Filter')"
                      @input="filterTable()"
                    ></b-form-input>
                  </td>
                  <td>
                    <select
                      class="form-control"
                      v-model="filter_Payment"
                      :placeholder="$t('Type to Filter')"
                      @change="filterTable()"
                    >
                      <option value="">{{ $t('Type to Filter') }}</option>
                      <option
                        v-for="(method, key) in payment_method_list"
                        :key="key"
                        :value="key"
                      >
                        {{ method.Name1 }}
                      </option>
                    </select>
                  </td>
                </template>
                <template #head(Note)="data">
                  <el-checkbox v-model="selectAll" @change="change_note_curpage(data)" true-label=true false-label=false style="height: 0px;"></el-checkbox>
                </template>
                <template #head(StatusDateTime)>
                  <div class="tableHeader">{{ $t("Date Time") }}</div>
                </template>
                <template #head(StoreName)>
                  <div class="tableHeader">{{ $t("Store") }}</div>
                </template>
                <template #head(TransactionNo)>
                  <div class="tableHeader">{{ $t("Transaction#") }}</div>
                </template>
                <template #head(SummarySubtotal)>
                  <div class="tableHeader">{{ $t("Subtotal") }}</div>
                </template>
                <template #head(SummaryDiscountSubtotal)>
                  <div class="tableHeader">{{ $t("Discount") }}</div>
                </template>
                <template #head(SummaryTax)>
                  <div class="tableHeader">{{ $t("Tax") }}</div>
                </template>
                <template #head(SummarySurcharge)>
                  <div class="tableHeader">{{ $t("Surcharge") }}</div>
                </template>
                <template #head(SummaryGratuity)>
                  <div class="tableHeader">{{ $t("Gratuity") }}</div>
                </template>
                <template #head(SummaryAllTotal)>
                  <div class="tableHeader">{{ $t("Total") }}</div>
                </template>
                <template #head(TotalPaidAmount)>
                  <div class="tableHeader">{{ $t("Payment") }}</div>
                </template>
                <template #head(PaymentStr)>
                  <div class="tableHeader">{{ $t("Method") }}</div>
                </template>
                <template #cell(Note)="data" >
                  <el-checkbox v-model="data.item.Note" @change="change_note(data.item)" true-label=true false-label=false></el-checkbox>
                </template>
                <template #cell(StatusDateTime)="data">
                  <div class="tableRow">{{ data.item.StatusDateTime.substring(0, 19) }}</div>
                </template>
                <template #cell(StoreName)="data">
                  <div class="tableRow">{{ data.item.StoreName }}</div>
                </template>
                <template #cell(TransactionNo)="data">
                  <div class="tableRow">{{ data.item.TransactionNo }}</div>
                </template>
                <template #cell(SummarySubtotal)="data">
                  <div class="tableRow">{{ "$ " + parseFloat(data.item.SummarySubtotal).toFixed(2) }}</div>
                </template>
                <template #cell(SummaryDiscountSubtotal)="data">
                  <div class="tableRow">{{ "$ " + parseFloat(data.item.SummaryDiscountSubtotal).toFixed(2) }}</div>
                </template>
                <template #cell(SummaryTax)="data">
                  <div class="tableRow">{{ "$ " + parseFloat(data.item.SummaryTax).toFixed(2) }}</div>
                </template>
                <template #cell(SummarySurcharge)="data">
                  <div class="tableRow">{{ "$ " + parseFloat(data.item.SummarySurcharge).toFixed(2) }}</div>
                </template>
                <template #cell(SummaryGratuity)="data">
                  <div class="tableRow">{{ "$ " + parseFloat(data.item.SummaryGratuity).toFixed(2) }}</div>
                </template>
                <template #cell(SummaryAllTotal)="data">
                  <div class="tableRow">{{ "$ " + parseFloat(data.item.SummaryAllTotal).toFixed(2) }}</div>
                </template>
                <template #cell(TotalPaidAmount)="data">
                  <div class="tableRow">{{ "$ " + parseFloat(data.item.TotalPaidAmount).toFixed(2) }}</div>
                </template>
                <template #cell(PaymentStr)="data">
                  <div class="tableRow">{{ data.item.PaymentStr }}</div>
                </template>
                <template slot="bottom-row">
                  <td v-if="donote"/>
                  <td/>
                  <td/>
                  <td class="tableFooter" style="font-size: 1.2rem; font-weight: bold; text-align: right;">
                    {{ $t("Total") + ": "}}
                  </td>
                  <td class="tableFooter" style="font-size: 1.2rem; font-weight: bold; text-align: right;">
                    {{ "$ " + parseFloat(summary_subtotal).toFixed(2) }}
                  </td>
                  <td class="tableFooter" style="font-size: 1.2rem; font-weight: bold; text-align: right;">
                    {{ "$ " + parseFloat(summary_discount_subtotal).toFixed(2) }}
                  </td>
                  <td class="tableFooter" style="font-size: 1.2rem; font-weight: bold; text-align: right;">
                    {{ "$ " + parseFloat(summary_tax).toFixed(2) }}
                  </td>
                  <td class="tableFooter" style="font-size: 1.2rem; font-weight: bold; text-align: right;">
                    {{ "$ " + parseFloat(summary_surcharge).toFixed(2) }}
                  </td>
                  <td class="tableFooter" style="font-size: 1.2rem; font-weight: bold; text-align: right;">
                    {{ "$ " + parseFloat(summary_gratuity).toFixed(2) }}
                  </td>
                  <td class="tableFooter" style="font-size: 1.2rem; font-weight: bold; text-align: right;">
                    {{ "$ " + parseFloat(summary_alltotal).toFixed(2) }}
                  </td>
                  <td class="tableFooter" style="font-size: 1.2rem; font-weight: bold; text-align: right;">
                    {{ "$ " + parseFloat(summary_paidtotal).toFixed(2) }}
                  </td>
                  <td/>
                </template>
                <template #row-details="row">
                  <vue-element-loading
                    :active="arrloading[row.item.IDn]"
                    spinner="bar-fade-scale"
                    color="#FF6700"
                    duration="2.0"
                    background-color="rgba(255, 255, 255, .5)"
                    size="100"
                  />
                  <b-card>
                    <b-row class="detail_header">
                      <b-col sm="2" class="text-sm-right"></b-col>
                      <b-col sm="4"><b>{{ "Name" }}</b></b-col>
                      <b-col sm="1" class="text-sm-right"><b>{{ "Quantity" }}</b></b-col>
                      <b-col sm="1" class="text-sm-right"><b>{{ "Subtotal" }}</b></b-col>
                      <b-col sm="1" v-for="taxType in row.item.AvailableItemTaxList" :key="taxType" class="text-sm-right">
                        <b>{{ taxType }}</b>
                      </b-col>
                      <b-col sm="1" class="text-sm-right"><b>{{ "Total" }}</b></b-col>
                    </b-row>
                    <b-row v-for="(item, index) in row.item.Items" :key="index" class="mb-2 detail_row">
                      <b-col sm="2" class="text-sm-right"><b>{{ "Item" + parseInt(index+1) }}</b></b-col>
                      <b-col sm="4">{{ item.Name1 }}</b-col>
                      <b-col sm="1" class="text-sm-right">{{ item.Qty }}</b-col>
                      <b-col sm="1" class="text-sm-right">{{ "$ " + item.ItemSubTotal }}</b-col>
                      <b-col sm="1" v-for="taxType in row.item.AvailableItemTaxList" :key="taxType" class="text-sm-right">
                        {{ taxType ? "$ " + Number(item[taxType]).toFixed(2) : "$ " + (0).toFixed(2) }}
                      </b-col>
                      <b-col sm="1" class="text-sm-right">{{ "$ " + (Number(item.ItemSubTotal) + Number(item.ItemTaxTotalAmount) - Number(item.ItemDiscountAmount)).toFixed(2) }}</b-col>
                    </b-row>
                    <div>
                      <b-button size="sm" variant="primary" @click="row.item._showDetails=false" style="float: right; margin-right: 12rem;">{{ "Hide Details" }}</b-button>
                    </div>
                  </b-card>                    
                </template>
              </b-table>
              <el-row>
                <el-col :span="18">
                  <span v-if="$i18n.locale == 'en'">
                    {{ $t("Total Transactions") + ": " + totalRows }} &ensp;&ensp;&ensp; {{ $t("Showing") + " " + nowShowing + " " + $t("of") + " " + totalRows  }}
                  </span>
                  <span v-else>
                    {{ $t("Total Transactions") + ": " + totalRows }} &ensp;&ensp;&ensp; {{ $t("Showing") + " " + totalRows + " " + $t("of") + " " + nowShowing  }}
                  </span>
                </el-col>
                <el-col :span="6">
                  <div style="float: right; margin-right: 10px">
                    <span>
                      {{ $t("Max Rows per page") }}
                    </span>
                    <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        {{ perPage }}
                      </template>
                      <b-dropdown-item 
                        v-for="page_option in per_page_option"
                        :key="page_option"
                        :command="page_option"
                        @click="handlePageSizeChanged">
                        {{ page_option }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </el-col>
              </el-row>
              <el-row style="margin-top: 20px">
                <el-col>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="summary-table"
                    ></b-pagination>
                    <!-- @change="handlePageChanged" -->
                </el-col>
              </el-row>
            </div>
            <div v-else>
              {{ $t('No Records') }}
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-container>
    <!-- <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    /> -->
  </div>
</template>
<script>
import { Table, TableColumn, Button, Pagination, Alert, Checkbox, Row, Col, Switch, } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";

import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    [Alert.name]: Alert,
    [Checkbox.name]: Checkbox,
    [Row.name]: Row,
    [Col.name]: Col,
    [Switch.name]: Switch,
    [flatPicker.name]: flatPicker,
    VueElementLoading,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      per_page_option: [5, 10, 20],
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      orderBy: "",
      fields: [
        {
          key: 'Note',
          label: 'Note',
          checkbox: true,
          sortable: false,
          tdClass: 'summary_row',
          thClass: "summary_header_note",
          thStyle: { width: "5%" },
          note_permission: true,
        },
        {
          key: 'StatusDateTime',
          label: 'Date Time',
          sortable: true,
          tdClass: 'summary_row',
          thClass: "summary_header",
          thStyle: { width: "8%" },
        },
        {
          key: 'StoreName',
          label: 'Store',
          sortable: true,
          tdClass: 'summary_row',
          thClass: "summary_header",
          thStyle: { width: "10%" },
        },
        {
          key: 'TransactionNo',
          label: 'Transaction #',
          sortable: true,
          tdClass: 'summary_row',
          thClass: "summary_header",
          thStyle: { width: "13.5%" },
        },
        {
          key: 'SummarySubtotal',
          label: 'Subtotal',
          sortable: true,
          tdClass: 'summary_row_right',
          thClass: "summary_header",
          thStyle: { width: "8%" },
        },
        {
          key: 'SummaryDiscountSubtotal',
          label: 'Discount',
          sortable: true,
          tdClass: 'summary_row_right',
          thClass: "summary_header",
          thStyle: { width: "8%" },
        },
        {
          key: 'SummaryTax',
          label: 'Tax',
          sortable: true,
          tdClass: 'summary_row_right',
          thClass: "summary_header",
          thStyle: { width: "8%" },
        },
        {
          key: 'SummarySurcharge',
          label: 'Surcharge',
          sortable: true,
          tdClass: 'summary_row_right',
          thClass: "summary_header",
          thStyle: { width: "8%" },
        },
        {
          key: 'SummaryGratuity',
          label: 'Gratuity',
          sortable: true,
          tdClass: 'summary_row_right',
          thClass: "summary_header",
          thStyle: { width: "8%" },
        },
        {
          key: 'SummaryAllTotal',
          label: 'Total',
          sortable: true,
          tdClass: 'summary_row_right',
          thClass: "summary_header",
          thStyle: { width: "8%" },
        },
        {
          key: 'TotalPaidAmount',
          label: 'Payment',
          sortable: true,
          tdClass: 'summary_row_right',
          thClass: "summary_header",
          thStyle: { width: "8%" },
        },
        {
          key: 'PaymentStr',
          label: 'Method',
          sortable: true,
          tdClass: 'summary_row_payment',
          thClass: "summary_header",
          thStyle: { width: "15%" },
        },
      ],
      filter_DateTime: null,
      filter_Store: null,
      filter_TransactionNo: null,
      filter_Subtotal: null,
      filter_Discount: null,
      filter_Tax: null,
      filter_Surcharge: null,
      filter_Gratuity: null,
      filter_PaidTotal: null,
      filter_AllTotal: null,
      filter_Payment: '',
      pickr_config: {
        dateFormat: "Y-m-d",
        maxDate: "today"
      },
      pickr_config2: {
        dateFormat: "Y-m-d",
        maxDate: "today",
        allowInput: false,
        enable: [],
        onChange: (selectedDates, dateStr, instance) => {
          this.filterTable();
        },
      },
      pickr_config3: {
        allowInput: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        maxDate: '23:59',
        time_24hr: true,
      },
      pickr_config4: {
        allowInput: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        maxDate: '23:59',
        time_24hr: true,
      },
      titleStyleObject: {
        textAlign: 'center',
        // textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '24px',
      },
      infoStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      infodStyleObject: {
        textAlign: 'left',
        fontWeight: '2px',
        fontSize: '18px',
      },
      markStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '20px',
      },
      headerStyleObject: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      titStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '18px',
        paddingLeft:'30px',
      },
      tidStyleObject: {
        textAlign: 'left',
        fontWeight: '2px',
        paddingLeft:'30px',
        fontSize: '18px',
      },
      nameStyleObject: {
        textAlign: 'center',
        fontWeight: '2px',
      },
      tftStyleObject: {
        fontWeight: 'bold',
      },
      rftStyleObject: {
        fontWeight: 'bold',
        fontSize: '19px',
      },
      rfdStyleObject: {
        fontWeight: '2px',
        fontSize: '19px',
      },
           
      row_dashed: {
        borderTop: '1px dashed #bbb',
        borderBottom: '1px dashed #bbb',
      }, 
      hr_dashed: {
        borderTop: '3px dashed #bbb',
      },
      hr_dotted: {
        borderTop: '3px dotted #bbb',
      },
      hr_solid: {
        borderTop: '3px solid #bbb',
        marginTop: '16px',
        marginBottom: '16px',
      },
      hr_rounded: {
        borderTop: '8px solid #bbb',
        borderRadius: '5px',
      },

      showloading: false,
      selectAll: false,

      token: "",
      user_id: 0,

      enable_dates: [],
      list_data: null,
      storelist: null,
      payment_method_list: null,
      available_item_tax_list: null,
      summary_subtotal: null,
      summary_discount_subtotal: null,
      summary_tax: null,
      summary_surcharge: null,
      summary_gratuity: null,
      summary_alltotal: null,
      summary_total: null,
      summary_paidtotal: null,
      display_from_date: null,
      display_to_date: null,
      display_transaction_no: null,
      display_store_name: null,
      display_payment_name: null,
      
      pkadmin: false,
      ac_time: true,
      is_void: false,
      from_date: null,
      from_time: '00:00',
      to_date: null,
      to_time: '23:59',
      cur_transaction_no: null,
      cur_store_id: null,
      cur_payment_method: null,

      export_url: "",
      show_success: false,
      donote: 0,
      detail_View: false,
      report_width: null,


      search_data: null,

      loadingDownload: false,
      readyDownload: false,
      sheets: [
        { name: "POSTransactionReport", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],
      excel_filename: 'POSTransactionReport.xlsx',
      excel_title: [],
      excel_summary: [],
      excel_total: 0,
      excel_data: {},

      delay_search_timer: null,
      arrloading: [],
    };
  },
  watch: {
    currentPage: function(val){
      this.delay_get_currentPage_data(true);
    },
    perPage: function(val){
      this.delay_get_currentPage_data(true);
    },
    list_data: function(val){
      this.convertBoolean();
      this.checkNotesOfCurPage();
      // this.setEnableDates();
    },
    enable_dates: function(val) {
      this.setEnableDates(val);
    },
    ac_time: function(val) {
      if (val) {
        this.from_time = '00:00';
        this.to_time = '23:59';
      }
    },
  },
  computed: {
    pdfBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
    computedFields() {
      if(!this.donote)
        return this.fields.filter(field => !field.note_permission);
      else
        return this.fields;
    },
    nowShowing: function(){
      var start = 1 + ((this.currentPage-1) * this.perPage);
      var end = this.perPage + ((this.currentPage-1) * this.perPage);
      return start + " - " + (end > this.totalRows ? this.totalRows : end);
    }
  },
  methods: {
    hasUtilityAuth,
    handleSortingChanged(ctx) {
      const order = ctx.sortDesc ? 'DESC' : 'ASC';
      this.orderBy = ctx.sortBy + " " + order;
      this.delay_get_currentPage_data(true);
    },
    handlePageChanged(value) {
      this.currentPage = value;
      this.delay_get_currentPage_data(true);
    },
    handlePageSizeChanged(value) {
      this.perPage = Number(value.target.innerText);
      this.delay_get_currentPage_data(true);
    },
    handleRowClicked(item, index) {
      if(!item._showDetails && !item.Items){
        this.getTransactionDetails(item);
      }
      this.$set(item, "_showDetails", !item._showDetails);
    },
    checkNotesOfCurPage(){
      let isAllChecked = true;
      for(var i=0; i<this.list_data.length; i++){
        // console.log(this.curPageItems[i].Note);
        if(this.list_data[i].Note == false || this.list_data[i].Note == "false"){
          isAllChecked = false;
          break;
        }
      }
      this.selectAll = isAllChecked;

      // if(isAllChecked){
      //   this.selectAll = true;
      // }
      // if(this.list_data.length == 0){
      //   this.selectAll = false;
      // }
    },
    
    clearDate() {
      this.$refs['fp'].fp.clear();
      this.filter_DateTime = null;
      this.filterTable();
    },
    filterTable() {
      // console.log("Just for showing");
      this.delay_get_currentPage_data(true);
    },

    delay_get_currentPage_data(filtering = false) {
      if (this.delay_search_timer) {
        clearTimeout(this.delay_search_timer);
        this.delay_search_timer = null;
      }
      var that = this;
      this.delay_search_timer = setTimeout(() => {
        that.get_currentPage_data(filtering);
      }, 400);
    },

    appendTransactionDetails(data, transaction){
      let temp = [];
      for(const id in data.trans_item_list){
        temp.push(data.trans_item_list[id])
      }
      transaction['Items'] = temp;
      transaction['AvailableItemTaxList'] = data.availableItemTaxList;
    },
    change_note_curpage(data){
      if(this.selectAll == true || this.selectAll == "true"){
        // Select all transactions on current page
        for(var i=0; i<this.list_data.length; i++){
          if(this.list_data[i].Note != true || this.list_data[i].Note != "true"){
            this.list_data[i].Note = true;
            this.change_note(this.list_data[i]);
          }
        }
      } else {
        // Un-select all transactions on current page
        // console.log("UN-SELECT ALL");
        for(var i=0; i<this.list_data.length; i++){
          if(this.list_data[i].Note != false || this.list_data[i].Note != "false"){
            this.list_data[i].Note = false;
            this.change_note(this.list_data[i]);
          }
        }
      }
      this.$forceUpdate();
    },
    convertBoolean(){
      for (var transaction in this.list_data) {
        if ((this.list_data[transaction].Note == "true") || (this.list_data[transaction].Note == true) || (this.list_data[transaction].Note == 1)) {
          this.list_data[transaction].Note = true;
        } else {
          this.list_data[transaction].Note = false;
        }
      }
    },
    change_note(trans) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      bodyFormData.append("IDn", trans.IDn);
      bodyFormData.append("Note", trans.Note);
      // console.log("IDn: ", trans.IDn, " Note: ", trans.Note);
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Pos_transaction_report/note",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.checkNotesOfCurPage();
              that.show_success = true;
              setTimeout(() => {
                that.show_success = false;
              }, 2000);
              // that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async getTransactionDetails(transaction){
      // this.showloading = true;
      this.arrloading[transaction.IDn] = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("trans_idn", transaction.IDn);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Pos_transaction_report/getDetail",
        data: bodyFormData,
      })
      .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            // console.log(response.data);
            var rt = response.data;
            if (rt.status == 0) {
              that.appendTransactionDetails(rt.data, transaction);
              that.arrloading[transaction.IDn] = false;
              that.$forceUpdate();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_currentPage_data(filtering = false) {
      this.showloading = true;
      // this will be called only after form is valid. You can do api call here to login
      
      if (!filtering || !this.search_data) {
        this.search_data = {};
        this.search_data["token"] = this.token;
        this.search_data["user_id"] = this.user_id;

        this.search_data["ACTime"] = (this.ac_time ? 1 : 0);
        this.search_data["IsVoid"] = (this.is_void ? 1 : 0);
        if (this.ac_time) {
          this.search_data["FromDate"] = this.from_date;
          this.search_data["ToDate"] = this.to_date;
        } else {
          this.search_data["FromDate"] = (this.from_date + ' ' + (this.from_time ? this.from_time : '00:00'));
          this.search_data["ToDate"] = (this.to_date + ' ' + (this.to_time ? this.to_time : '23:59'));
        }

        if (this.cur_transaction_no) {
          this.search_data["TransactionNo"] = this.cur_transaction_no;
        }
        if (this.cur_store_id) {
          this.search_data["StoreID"] = this.cur_store_id;
        }
        if (this.cur_payment_method) {
          this.search_data["PaymentStr"] = this.cur_payment_method;
        }
      }
      var bodyFormData = new FormData();
      let keys = Object.keys(this.search_data);
      for (let i = 0; i < keys.length; i++) {
        bodyFormData.append(keys[i], this.search_data[keys[i]]);
      }

      if (filtering) {
        if (this.filter_DateTime) {
          bodyFormData.append("TransactionDate", this.filter_DateTime);
          // if (bodyFormData.has("FromDate")) {
          //   bodyFormData.delete("FromDate");
          // }
          // if (bodyFormData.has("ToDate")) {
          //   bodyFormData.delete("ToDate");
          // }
        }
        if (this.filter_Store) {
          bodyFormData.append("StoreName", this.filter_Store);
        }
        if (this.filter_TransactionNo) {
          bodyFormData.append("TransactionNo_filter", this.filter_TransactionNo);
        }
        if (this.filter_Subtotal) {
          bodyFormData.append("SummarySubtotal", this.filter_Subtotal);
        }
        if (this.filter_Discount) {
          bodyFormData.append("SummaryDiscountSubtotal", this.filter_Discount);
        }
        if (this.filter_Tax) {
          bodyFormData.append("SummaryTax", this.filter_Tax);
        }
        if (this.filter_Surcharge) {
          bodyFormData.append("SummarySurcharge", this.filter_Surcharge);
        }
        if (this.filter_Gratuity) {
          bodyFormData.append("SummaryGratuity", this.filter_Gratuity);
        }
        if (this.filter_AllTotal) {
          bodyFormData.append("SummaryAllTotal", this.filter_AllTotal);
        }
        if (this.filter_PaidTotal) {
          bodyFormData.append("TotalPaidAmount", this.filter_PaidTotal);
        }
        if (this.filter_Payment) {
          bodyFormData.append("PaymentStr_filter", this.filter_Payment);
        }
      } else {
        this.filter_DateTime = null;
        this.filter_Store = null;
        this.filter_TransactionNo = null;
        this.filter_Subtotal = null;
        this.filter_Discount = null;
        this.filter_Tax = null;
        this.filter_Surcharge = null;
        this.filter_Gratuity = null;
        this.filter_PaidTotal = null;
        this.filter_AllTotal = null;
        this.filter_Payment = '';
      }
      
      bodyFormData.append("filtering", filtering);
      bodyFormData.append("page", this.currentPage);
      bodyFormData.append("per_page", this.perPage);
      bodyFormData.append("order_by", this.orderBy);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_transaction_report/search",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (filtering) {
                // that.available_item_tax_list = rt.data.available_item_tax_list;
                // that.summary_subtotal = rt.data.summary_subtotal;
                // that.summary_discount_subtotal = rt.data.summary_discount_subtotal;
                // that.summary_tax = rt.data.summary_tax;
                // that.summary_total = rt.data.summary_total;
                // that.enable_dates = rt.data.enable_dates;
                that.list_data = rt.data.list_data;
                // that.donote = rt.data.donote;
                // that.display_from_date = rt.data.display_from_date;
                // that.display_to_date = rt.data.display_to_date;
                // that.display_transaction_no = rt.data.display_transaction_no;
                // that.display_store_name = rt.data.display_store_name;
                // that.display_payment_name = rt.data.display_payment_name;
                that.totalRows = rt.data.total_transaction_count;
              } else {
                // that.available_item_tax_list = rt.data.available_item_tax_list;
                that.summary_subtotal = rt.data.summary_subtotal;
                that.summary_discount_subtotal = rt.data.summary_discount_subtotal;
                that.summary_tax = rt.data.summary_tax;
                that.summary_surcharge = rt.data.summary_surcharge;
                that.summary_gratuity = rt.data.summary_gratuity;
                that.summary_total = rt.data.summary_total;
                that.summary_alltotal = rt.data.summary_alltotal;
                that.summary_paidtotal = rt.data.summary_paidtotal;
                that.enable_dates = rt.data.enable_dates;
                that.list_data = rt.data.list_data;
                that.display_from_date = rt.data.display_from_date;
                that.display_to_date = rt.data.display_to_date;
                that.display_transaction_no = rt.data.display_transaction_no;
                that.display_store_name = rt.data.display_store_name;
                that.display_payment_name = rt.data.display_payment_name;
                that.totalRows = rt.data.total_transaction_count;
                that.donote = that.hasUtilityAuth('NotePermission');
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async excel_download(download) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      bodyFormData.append("ACTime", this.ac_time ? 1 : 0);
      if (this.ac_time) {
        bodyFormData.append("FromDate", this.from_date);
        bodyFormData.append("ToDate", this.to_date);
      } else {
        bodyFormData.append("FromDate", this.from_date + ' ' + (this.from_time ? this.from_time : '00:00'));
        bodyFormData.append("ToDate", this.to_date + ' ' + (this.to_time ? this.to_time : '23:59'));
      }

      if (this.cur_transaction_no) {
        bodyFormData.append("TransactionNo", this.cur_transaction_no);
      }
      if (this.cur_store_id) {
        bodyFormData.append("StoreID", this.cur_store_id);
      }
      if (this.cur_payment_method) {
        bodyFormData.append("PaymentStr", this.cur_payment_method);
      }
      bodyFormData.append("order_by", this.orderBy);
      //////////////////////////////////////////////////////////////////////////
      this.loadingDownload = true;
      this.excel_data = [];
      this.excel_total = 0;
      let offset = 0;
      let limit = 3000;
      while (true) {
        await this.get_data(bodyFormData, offset, limit, (offset + limit >= this.excel_total));
        if (offset + limit >= this.excel_total) {
          break;
        } else {
          offset += limit;
        }
      }

      let printDate = this.get_today();
      this.sheets = [{name: "POSTransactionReport", data: []}];
      this.excel_filename = 'POSTransactionReport_' + printDate + '.xlsx';
      this.excel_title.push('Print Date: ' + printDate);
      if (this.excel_title.length < 9) {
        for (let i = this.excel_title.length; i <= 9; i++) {
          this.excel_title.push('');
        }
      }
      this.sheets[0].data.push(this.excel_title);
      this.sheets[0].data.push(['','','','','','','','','']);
      this.sheets[0].data.push(['Date Time', 'Store', 'Transaction#', 'Subtotal', 'Discount', 'Tax', 'Surcharge', 'Gratuity', 'Total', 'Payment', 'Method', 'Cashier']);
      let keys = Object.keys(this.excel_data);
      for (let i = 0; i < keys.length; i++) {
        this.sheets[0].data.push(this.excel_data[keys[i]]);
      }
      this.sheets[0].data.push(this.excel_summary);
      
      this.loadingDownload = false;
      this.$nextTick(() => {
        download();
      });
    }, 
    async get_data(bodyFormData, offset, limit, get_summary = false) {
      if (this.excel_data == null) {
        this.excel_data = {};
      }

      if (bodyFormData.has("offset")) {
        bodyFormData.set("offset", offset);
      } else {
        bodyFormData.append("offset", offset);
      }
      if (bodyFormData.has("limit")) {
        bodyFormData.set("limit", limit);
      } else {
        bodyFormData.append("limit", limit);
      }

      if (get_summary) {
        if (bodyFormData.has("get_summary")) {
          bodyFormData.set("get_summary", true);
        } else {
          bodyFormData.append("get_summary", true);
        }
      } else {
        if (bodyFormData.has("get_summary")) {
          bodyFormData.delete("get_summary");
        }
      }
      var that = this;
      await axios({
        method: "post",
        url: "/Api/Web/Pos_transaction_report/search_data",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.list_data) {
                for (let i = 0; i < rt.data.list_data.length; i++) {
                  let r = rt.data.list_data[i];
                  that.excel_data[i + offset] = [
                    r.StatusDateTime, 
                    r.StoreName, 
                    r.TransactionNo, 
                    r.SummarySubtotal ? parseFloat(r.SummarySubtotal) : 0, 
                    r.SummaryDiscountSubtotal ? parseFloat(r.SummaryDiscountSubtotal) : 0, 
                    r.SummaryTax ? parseFloat(r.SummaryTax) : 0, 
                    r.SummarySurcharge ? parseFloat(r.SummarySurcharge) : 0, 
                    r.SummaryGratuity ? parseFloat(r.SummaryGratuity) : 0, 
                    r.SummaryAllTotal ? parseFloat(r.SummaryAllTotal) : 0, 
                    r.TotalPaidAmount ? parseFloat(r.TotalPaidAmount) : 0,
                    r.PaymentStr,
                    r.Cashier,
                  ];
                }
              }
              if (rt.data.summary) {
                that.excel_total = parseInt(rt.data.summary.count);
                that.excel_summary = [
                  '', 
                  '', 
                  'Summary:', 
                  rt.data.summary.SummarySubtotal ? parseFloat(rt.data.summary.SummarySubtotal) : 0, 
                  rt.data.summary.SummaryDiscountSubtotal ? parseFloat(rt.data.summary.SummaryDiscountSubtotal) : 0, 
                  rt.data.summary.SummaryTax ? parseFloat(rt.data.summary.SummaryTax) : 0, 
                  rt.data.summary.SummarySurcharge ? parseFloat(rt.data.summary.SummarySurcharge) : 0, 
                  rt.data.summary.SummaryGratuity ? parseFloat(rt.data.summary.SummaryGratuity) : 0, 
                  rt.data.summary.SummaryAllTotal ? parseFloat(rt.data.summary.SummaryAllTotal) : 0, 
                  rt.data.summary.SummaryPaidTotal ? parseFloat(rt.data.summary.SummaryPaidTotal) : 0, 
                  '',
                  '',
                ];
                that.excel_title = [];
                if (rt.data.summary.display_transaction_no !== undefined && rt.data.summary.display_transaction_no) {
                  that.excel_title.push('Transaction#: ' + rt.data.summary.display_transaction_no);
                }
                if (rt.data.summary.display_store_name !== undefined && rt.data.summary.display_store_name) {
                  that.excel_title.push('Store: ' + rt.data.summary.display_store_name);
                }
                if (rt.data.summary.display_payment_name !== undefined && rt.data.summary.display_payment_name) {
                  that.excel_title.push('Payment: ' + rt.data.summary.display_payment_name);
                }
                if (rt.data.summary.display_from_date !== undefined && rt.data.summary.display_from_date) {
                  that.excel_title.push('From Date: ' + rt.data.summary.display_from_date);
                }
                if (rt.data.summary.display_to_date !== undefined && rt.data.summary.display_to_date) {
                  that.excel_title.push('To Date: ' + rt.data.summary.display_to_date);
                }
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    setEnableDates(value){
      // let array = [];
      // for(var key in this.list_data){
      //   var date = this.list_data[key].StatusDateTime.substring(0, 10);
      //   if (!array.includes(date)) {
      //     array.push(date);
      //   }
      // }
      // this.pickr_config2.enable = array;
      // console.log('setEnableDates', value);
      this.pickr_config2.enable = value;
    },
    form_cancel() {
      this.$router.push("/store");
    },
    async form_search() {
      this.get_currentPage_data();
      
      // this.sheets = [[]];
      // this.get_full_data();
    },
    get_today() {
      var today = new Date();
      // console.log('today', today);
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      return yyyy + '-' + mm + '-' + dd;
    },

    async get_init_data() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Pos_transaction_report/",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.storelist;
              that.payment_method_list = rt.data.payment_method_list;
              // that.get_currentPage_data();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.from_date = this.get_today();
      this.to_date = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.get_init_data();
    },
  },
  mounted() {
    let name = getStore("username");
    if (name == "PKAdmin") {
      this.pkadmin = true;
    }

    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
@media (min-width:992px) {
  .buttonRow {
    margin-top: 25px;
  }

  .switchElement {
    margin-top: 1rem;
    /* margin-left: 4rem; */
    text-align: center;
  }
}
@media (min-width:0px) and (max-width:1200px){
  .searchParam {
    font-size: 1rem !important;
  }
}
@media (min-width:1601px) {
  .tableHeader {
    font-size: 1rem !important;
  }
  .tableFooter {
    font-size: 1.1rem !important;
  }
}
@media (min-width:1401px) and (max-width:1600px){
  .tableHeader {
    font-size: 0.8rem !important;
  }
  .tableFooter {
    font-size: 1.1rem !important;
  }
}
@media (min-width:1181px) and (max-width:1400px){
  .tableHeader {
    font-size: 0.7rem !important;
  }
  .tableFooter {
    font-size: 1.0rem !important;
  }
}
@media (min-width:0px) and (max-width:1180px){
  .tableHeader {
    font-size: 0.63rem !important;
  }
  .tableRow {
    font-size: 0.85rem !important;
  }
  .tableFooter {
    font-size: 0.9rem !important;
  }
}
.content {
  padding-top: 60px;
}
.summary_row {
  font-size: 1rem !important;
  text-align: center;
  white-space: unset !important;
}
.summary_row_right {
  font-size: 1rem !important;
  text-align: right;
  white-space: unset !important;
}
.summary_row_payment {
  font-size: 1rem !important;
  white-space: unset !important;
}
.summary_header {
  font-size: 1.0035rem !important;
  white-space: unset !important;
}
.summary_header_note {
  font-size: 1.0035rem !important;
  white-space: unset !important;
  text-align: center;
}
.detail_row {
  font-size: 1rem !important;
  white-space: unset !important;
}
.detail_header {
  font-size: 1.0035rem !important;
  white-space: unset !important;
}
.flatpickr-input {
  background-color: #ffffff !important;
}
.clearDateButton {
  outline: 0;
  padding: 8px 8px;
  height: calc(1.5em + 0.75rem + 6px);
  border-radius: 0 0.25rem 0.25rem 0 !important;
  border: 1px solid #ced4da;
  color: #3D3D3D;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.clearDateButton:focus {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  border: 1px solid #ced4da !important;
  outline: 0 !important;
}
#filterTableFP {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: #ced4da !important;
}
</style>
<style scoped src="bootstrap/dist/css/bootstrap.css"></style>
<style src="bootstrap-vue/dist/bootstrap-vue.css"></style>
